
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Julius+Sans+One&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}

.logoFont {
  display:flex;
  font-family: 'Julius Sans One', sans-serif;
  color: #deded8;
  font-size: 2.0em;
  font-weight: 200;
  text-align: center;
  justify-content: center;
}

.navFont {
  font-family: 'Julius Sans One', sans-serif;
  color: #deded8;
  font-size: 1.8em;
  font-weight: 200;
}

.small-navFont {
  font-family: 'Julius Sans One', sans-serif;
  color: #deded8;
  font-size: 1.6em;
  font-weight: 200;
}

.smFont {
  display:flex;
  font-family: 'Julius Sans One', sans-serif;
  color: #deded8;
  font-size: 1.6em;
  font-weight: 200;
  text-align: center;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



